'use client'
import { GifPanelContext } from '@/app/components/detail/gif-panel-context-manager'
import FavoriteIcon from '@/app/icons/favorite'
import FavoritesContext, { useToggleFavorites } from '@/context/favorites-manager'
import { FlashMessageContext } from '@/context/flash-message'
import UserContext from '@/context/user-context'
import { cn } from '@/util/cn'
import { IGif } from '@giphy/js-types'
import { getContentOfGif, getCreatorOfGif, setGADataLayer } from 'analytics'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'

type Props = {
    children?: ReactNode
    className?: string
    iconClassName?: string
    iconSize?: number
    gif: IGif
    isFavorite: boolean
    hideLabel?: boolean
}

const favoriteMessageTimeout = 2000
const FavoriteButton = ({ gif, iconSize, className = '', isFavorite, iconClassName, hideLabel }: Props) => {
    const { user } = useContext(UserContext)
    const [hasSeenMessage, setHasSeenMessage] = useLocalStorage('hasSeenLocalFavoritesMessage', false)
    const { showMessage } = useContext(FlashMessageContext)
    const toggleFavorite = useToggleFavorites()
    const { setFavorites } = useContext(FavoritesContext)
    const { setPanel } = useContext(GifPanelContext)
    useEffect(() => {
        // update the context so toggleFavorite works
        if (isFavorite) setFavorites((fav) => [...fav, gif.id as string])
    }, [isFavorite, gif.id, setFavorites])
    const [clickedFavorite, setIsClickedFavorite] = useState<boolean | undefined>(undefined)
    const isActualFavorite = clickedFavorite === undefined ? isFavorite : clickedFavorite
    const [label, setLabel] = useState('Favorite')
    useEffect(() => {
        let timeout: NodeJS.Timeout
        if (clickedFavorite) {
            setLabel('Favorited!')
            timeout = setTimeout(() => {
                setLabel('Favorite')
            }, favoriteMessageTimeout)
            setPanel('favorited')
        } else if (clickedFavorite === false) {
            timeout = setTimeout(() => {
                setLabel('Favorite')
            }, favoriteMessageTimeout)
            setLabel('Removed')
        }
        return () => clearTimeout(timeout)
    }, [clickedFavorite, setPanel])
    return (
        <div
            className={className}
            onClick={async (e) => {
                e.preventDefault()
                e.stopPropagation()
                // manage local state now
                setIsClickedFavorite(!isActualFavorite)
                const isFavorite = await toggleFavorite(gif.id as string)
                if (isFavorite) {
                    try {
                        setGADataLayer({
                            event: 'favorite_success',
                            options: {
                                content: getContentOfGif(gif),
                                creator: { ...getCreatorOfGif(gif), user_is_creator: user?.id === gif.user?.id },
                            },
                        })
                    } catch (error) {}
                }
                if (!user && !hasSeenMessage) {
                    setHasSeenMessage(true)
                    showMessage({ message: 'Favorite saved! You can access your favorites from the user menu.' })
                }
            }}
        >
            <FavoriteIcon
                gradientId={`${gif.id}-favorite`}
                width={iconSize}
                height={iconSize}
                // override the utility for all icons if favorited
                className={cn(
                    '[--stop-color-1:--giphy-white] [--stop-color-2:--giphy-white]',
                    iconClassName,
                    isActualFavorite && '[--stop-color-1:--giphy-pink] [--stop-color-2:--giphy-red]',
                    label === 'Removed' && '![--stop-color-1:--giphy-white] ![--stop-color-2:--giphy-white]'
                )}
            />
            {!hideLabel && (
                <label
                    className={cn(
                        'hidden md:block',
                        'text-lg font-bold',
                        label === 'Removed'
                            ? 'text-giphyWhite'
                            : 'from-giphyRed to-giphyPink bg-clip-text group-hover:bg-gradient-to-b group-hover:text-transparent'
                    )}
                >
                    {label}
                </label>
            )}
        </div>
    )
}
export default FavoriteButton
