'use client'
import NavigateLeftIcon from '@/app/icons/navigate-left'
import NavigateRightIcon from '@/app/icons/navigate-right'
import { IGif } from '@giphy/js-types'
import { useRouter } from 'next/navigation'
import { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { relativeUrl } from '../../../../util/url'
import { useRelatedGifPagination } from './use-related-pagination'

const fadeOut = keyframes`
    0% {
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    80%{
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`
const Container = styled.div`
    position: absolute;
    inset: 0;
    animation: ${fadeOut} 2.5s ease-in-out forwards;
    animation-delay: 0.5s;
    opacity: 0;
`

const LeftArrow = styled(NavigateLeftIcon)`
    position: absolute;
    left: 5px;
    top: 50%;
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
`
const RightArrow = styled(NavigateRightIcon)`
    position: absolute;
    right: 5px;
    top: 50%;
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
`

type Props = {
    gif: IGif
}

const GifNav = ({ gif }: Props) => {
    const { prev, next } = useRelatedGifPagination(gif, 4)
    const router = useRouter()
    const [animationIndex, setAnimationIndex] = useState(0)
    return (
        <Container
            key={animationIndex}
            onClick={() => {
                setAnimationIndex(animationIndex + 1)
            }}
        >
            {prev && (
                <div
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        router.push(relativeUrl(prev.url))
                    }}
                >
                    <LeftArrow width={20}></LeftArrow>
                </div>
            )}
            {next && (
                <div
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        router.push(relativeUrl(next.url))
                    }}
                >
                    <RightArrow width={20}></RightArrow>
                </div>
            )}
        </Container>
    )
}

export default GifNav
