'use client'
import { GifPanelContext } from '@/app/components/detail/gif-panel-context-manager'
import { IGif, IUser } from '@giphy/js-types'
import { getContentOfGif, getCreatorOfGif, setGADataLayer } from 'analytics'
import download from 'downloadjs'
import { usePathname, useRouter } from 'next/navigation'
import { ReactNode, useContext } from 'react'
import sanitize from 'sanitize-filename'
import { timeoutFetch } from 'utils/src/api/fetch'
import { ShareUrlRenditions } from 'utils/src/media/sharing'

type Props = {
    className?: string
    gif: IGif
    children: ReactNode
}

export const DownloadToggle = ({ gif, className = '', children, user }: Props & { user?: IUser }) => {
    const router = useRouter()
    const pathname = usePathname()
    const { setPanel } = useContext(GifPanelContext)
    return (
        <div
            className={className}
            onClick={async () => {
                if (!user) {
                    router.push(`/login?next=${encodeURIComponent(pathname)}`)
                } else {
                    const url = gif.images.original.url
                    const imageUrl = url.replace('media.giphy.com', 'i.giphy.com')
                    const result = await timeoutFetch({ url: imageUrl, timeout: 10000 })
                    const blob = await result.blob()
                    // http://danml.com/download.html
                    download(blob, sanitize(`${gif.title}`))
                    setPanel('downloaded')
                    setGADataLayer({
                        event: 'file_download',
                        options: {
                            content: getContentOfGif(gif, {
                                rendition: ShareUrlRenditions.Original,
                                format: gif.images.original.url,
                            }),
                            creator: getCreatorOfGif(gif),
                        },
                    })
                }
            }}
        >
            {children}
        </div>
    )
}

export const CopyButton = ({ className = '', children, gif }: Props) => {
    const { setPanel } = useContext(GifPanelContext)
    return (
        <div
            className={className}
            onClick={() => {
                const imageUrl = gif.images.original.url
                navigator.clipboard.writeText(imageUrl)
                setPanel('copied')
                setGADataLayer({
                    event: 'share_success',
                    options: {
                        social: {
                            socialChannel: 'share',
                        },
                        content: getContentOfGif(gif, {
                            rendition: ShareUrlRenditions.Original,
                            format: gif.images.original.url,
                        }),
                        creator: getCreatorOfGif(gif),
                    },
                })
            }}
        >
            {children}
        </div>
    )
}
