'use client'
import FavoriteButton from '@/components/favorite-button'
import FavoritesContext from '@/context/favorites-manager'
import { IGif } from '@giphy/js-types'
import { ReactNode, useContext } from 'react'

const LocalFavorite = ({
    gif,
    children,
    className,
    iconSize,
    iconClassName,
}: {
    gif: IGif
    children?: ReactNode
    className?: string
    iconClassName?: string
    iconSize?: number
}) => {
    const isFavorite = useContext(FavoritesContext).favorites.includes(gif.id as string)
    return (
        <FavoriteButton
            gif={gif}
            iconSize={iconSize}
            isFavorite={isFavorite}
            className={className}
            iconClassName={iconClassName}
        >
            {children}
        </FavoriteButton>
    )
}

export default LocalFavorite
