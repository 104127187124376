'use client'
import { createContext, ReactNode, useCallback, useContext, useReducer } from 'react'

type PanelState = {
    panel: PanelType
    setPanel: (panel: PanelType) => void
}

export type PanelType = 'embed' | 'share' | 'info' | 'favorited' | 'copied' | 'downloaded' | 'download' | 'none'

const initialState: PanelState = {
    panel: 'none',
    setPanel: () => {},
}

const panelReducer = (state: PanelState, action: { type: PanelType }): PanelState => {
    return { ...state, panel: action.type }
}

export const GifPanelContext = createContext<PanelState>(initialState)

type Props = { children: React.ReactNode }

const GifPanelContextManager = ({ children }: Props) => {
    const [state, dispatch] = useReducer(panelReducer, initialState)
    const setPanel = useCallback((panel: PanelType) => {
        dispatch({ type: panel })
    }, [])
    return (
        <GifPanelContext.Provider
            value={{
                ...state,
                setPanel,
            }}
        >
            {children}
        </GifPanelContext.Provider>
    )
}

// useful for server components
export const ClickToShowPanel = ({
    children,
    panel,
    className,
}: {
    panel: PanelType
    children: ReactNode
    className?: string
}) => {
    const { panel: currentPanel, setPanel } = useContext(GifPanelContext)
    return (
        <div
            className={className}
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setPanel(currentPanel === panel ? 'none' : panel)
            }}
        >
            {children}
        </div>
    )
}

export default GifPanelContextManager
