'use client'
import { modalClassName } from '@/app/components/modal'
import { cn } from '@/util/cn'
import useClientRender from '@/util/use-client-render'
import { IGif } from '@giphy/js-types'
import { useSearchParams } from 'next/navigation'
import { ReactNode, useState } from 'react'
import { createPortal } from 'react-dom'
import FlagForm from './flag-form'

export const useShowReportForm = () => {
    const params = useSearchParams()
    const reportPlatform = params.get('report')
    const [showFlagForm, setShowFlagForm] = useState(!!reportPlatform)
    return [showFlagForm, setShowFlagForm] as const
}

type Props = { gif: IGif; children: ReactNode; className?: string }
function ReportButtonManager({ gif, children, className }: Props) {
    const [showFlagForm, setShowFlagForm] = useShowReportForm()
    return (
        <>
            <div
                className={className}
                onClick={() => {
                    setShowFlagForm(true)
                }}
            >
                {children}
            </div>
            {showFlagForm && <ReportModal gifId={gif.id} onClose={() => setShowFlagForm(false)} />}
        </>
    )
}

export const ReportModal = ({ gifId, onClose }: { gifId?: IGif['id']; onClose: () => void }) => {
    const render = useClientRender()
    return render
        ? createPortal(
              <div className={cn(modalClassName, 'overflow-y-auto bg-opacity-90')} onClick={() => onClose()}>
                  <FlagForm gif={gifId as string} toggleForm={() => onClose()} />
              </div>,
              document.body,
              `${gifId}-flag`
          )
        : null
}

export default ReportButtonManager
