'use client'
import { cn } from '@/util/cn'
import Link from 'next/link'
import { ReactNode } from 'react'
import styled from 'styled-components'
const Container = styled.div`
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`

/**
 * Convenience component for grouping sections together
 *
 * Group sections together with consistent spacing.
 * There is the space between sections, and the space between items in a section
 * There is also a margin prop that can be used to add space around the group
 */
export const SectionGroup = styled.div<{ gap: number; margin?: string; sectionGap?: number }>`
    display: flex;
    flex-direction: column;
    gap: ${({ gap }) => (gap ? `${gap}px` : 'unset')};
    margin: ${({ margin }) => margin || 'unset'};
    section {
        display: flex;
        flex-direction: column;
        gap: ${({ sectionGap }) => (sectionGap ? `${sectionGap}px` : 0)};
    }
`

const H = styled.h2``

type Props = {
    Icon?: ReactNode
    link?: string
    // support for legacy pages like /stories
    isHref?: boolean
    label?: ReactNode
    className?: string
    children?: ReactNode
    // naming this `as` can mess up types
    tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

const spacingClassName = 'mb-0.5 px-2 md:px-0'
const headerContainerClassName = 'flex items-center justify-between'

/**
 * has standardized padding
 */
export const HeaderRow = ({ className, children }: { className?: string; children: ReactNode }) => {
    return <Container className={cn(spacingClassName, className)}>{children}</Container>
}

export const SectionHeader = ({ Icon, label, className = '', tag = 'h4', link, isHref, children }: Props) => {
    const Row = () => (
        <HeaderRow className={cn(headerContainerClassName, className)}>
            {label && (
                <div className="flex items-center gap-1.5">
                    {Icon}
                    <H as={tag}>{label}</H>
                    {link && (
                        <div className="ss-icon ss-navigateright text-giphyLightGrey inline-block h-[13px] text-xs"></div>
                    )}
                </div>
            )}
            {children}
        </HeaderRow>
    )
    return link ? (
        isHref ? (
            <a href={link}>
                <Row />
            </a>
        ) : (
            <Link href={link}>
                <Row />
            </Link>
        )
    ) : (
        <Row />
    )
}
