import { SVGAttributes } from 'react'

const CloseIcon = (props: SVGAttributes<SVGElement>) => (
    <svg fill={props.color || 'white'} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.1804 22C18.2992 22 18.4105 21.9592 18.5145 21.8775L21.8775 18.5145C21.9592 18.4105 22 18.2992 22 18.1804C22 18.0542 21.9592 17.9391 21.8775 17.8352L16.0423 12L21.8775 6.16481C21.9592 6.06088 22 5.9458 22 5.8196C22 5.70082 21.9592 5.58946 21.8775 5.48552L18.5145 2.1225C18.4105 2.04083 18.2992 2 18.1804 2C18.0542 2 17.9391 2.04083 17.8352 2.1225L12 7.95768L6.16481 2.1225C6.06088 2.04083 5.9458 2 5.8196 2C5.70082 2 5.58946 2.04083 5.48552 2.1225L2.1225 5.48552C2.04083 5.58946 2 5.70082 2 5.8196C2 5.9458 2.04083 6.06088 2.1225 6.16481L7.95768 12L2.1225 17.8352C2.04083 17.9391 2 18.0542 2 18.1804C2 18.2992 2.04083 18.4105 2.1225 18.5145L5.48552 21.8775C5.58946 21.9592 5.70082 22 5.8196 22C5.9458 22 6.06088 21.9592 6.16481 21.8775L12 16.0423L17.8352 21.8775C17.9391 21.9592 18.0542 22 18.1804 22Z"
        />
    </svg>
)

export default CloseIcon
