'use client'
import { GifPanelContext } from '@/app/components/detail/gif-panel-context-manager'
import { NexaBlack } from '@/styles/fonts'
import { cn } from '@/util/cn'
import { useContext, useEffect, useState } from 'react'

const states = new Set(['favorited', 'copied', 'downloaded'])

const GifMessagePanel = () => {
    const { panel, setPanel } = useContext(GifPanelContext)
    const [animationState, setAnimationState] = useState<'opening' | 'closing' | 'closed'>('closed')
    const isMessage = states.has(panel)
    useEffect(() => {
        let timeout: NodeJS.Timeout
        if (isMessage) {
            setAnimationState('opening')
            timeout = setTimeout(() => {
                setAnimationState('closing')
            }, 2000)
        } else {
            setAnimationState('closed')
        }
        return () => clearTimeout(timeout)
    }, [isMessage, setPanel, setAnimationState])
    let colorClasses = 'to-giphyRed from-giphyPink'
    let text = 'Added to Favorites!'
    switch (panel) {
        case 'copied':
            text = 'Copied to Clipboard!'
            colorClasses = 'to-giphyGreen from-giphyAqua'
            break
        case 'downloaded':
            text = 'Saved to Downloads!'
            colorClasses = 'from-giphyBlue to-giphyIndigo'
            break
        default:
            break
    }
    return isMessage ? (
        <>
            <div
                key={`message-${animationState}`}
                className={cn(
                    animationState === 'opening'
                        ? 'animate-[showGifMessage_250ms_ease-in-out_forwards]'
                        : 'animate-[fadeIn_250ms_ease-out_reverse_both]',
                    'absolute inset-0 flex items-center justify-center'
                )}
                onAnimationEnd={() => {
                    if (animationState === 'closing' && isMessage) {
                        setAnimationState('closed')
                        setPanel('none')
                    }
                }}
            >
                <div className={cn(colorClasses, 'absolute inset-0 bg-gradient-to-b opacity-80')}></div>
            </div>
            <div
                className={cn(
                    `select-none p-2 text-center text-3xl`,
                    'transition-opacity',
                    animationState === 'opening' ? 'opacity-100 delay-200' : 'opacity-0',
                    'absolute inset-0 flex items-center justify-center',
                    NexaBlack.className
                )}
            >
                {text}
            </div>
        </>
    ) : null
}

export default GifMessagePanel
