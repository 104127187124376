import { SVGAttributes } from 'react'
type Props = SVGAttributes<SVGElement> & {
    gradientId?: string
}

const FavoriteIcon = ({ gradientId = 'favorites-icon', ...props }: Props) => (
    <svg viewBox="0 0 22 20" xmlns="http://www.w3.org/2000/svg" {...props}>
        <defs>
            <linearGradient x1="0%" y1="10%" x2="0%" y2="90%" id={gradientId}>
                <stop id="gradient-stop-1" offset="0%" stopColor="var(--stop-color-1)" />
                <stop id="gradient-stop-2" offset="100%" stopColor="var(--stop-color-2)" />
            </linearGradient>
        </defs>
        <path
            fill={props.className?.indexOf('--stop-color-1') === -1 ? '' : `url(#${gradientId})`}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9307 11.9353L11.3231 19.8661C11.2015 19.9554 11.0906 20 10.9904 20C10.8545 20 10.7436 19.9554 10.6578 19.8661L3.05013 11.9353C2.1631 11.0132 1.46386 10.0707 0.952383 9.10764C0.440911 8.14463 0.136893 7.22997 0.0403214 6.36364C-0.0562504 5.4973 0.0206483 4.6756 0.271019 3.8985C0.521391 3.1214 0.939862 2.43168 1.52645 1.82934C2.28471 1.04108 3.17352 0.507532 4.19289 0.228668C5.21225 -0.0501953 6.23161 -0.0501953 7.25098 0.228668C8.27034 0.507532 9.15915 1.04108 9.91741 1.82934C10.3395 2.26808 10.6971 2.76259 10.9904 3.31288C11.2837 2.76259 11.6414 2.26808 12.0634 1.82934C12.8217 1.03365 13.7105 0.494518 14.7299 0.211936C15.7493 -0.0706455 16.7704 -0.0706455 17.7933 0.211936C18.8163 0.494518 19.7105 1.03365 20.4759 1.82934C21.2556 2.6399 21.7385 3.5843 21.9244 4.66258C22.1104 5.74085 21.9531 6.91578 21.4523 8.1874C20.9516 9.45901 20.1111 10.7083 18.9307 11.9353Z"
        />
    </svg>
)
export default FavoriteIcon
